
import { defineComponent } from "vue";

//  utils

import { timeFormat } from "@/utils/dateFormat";
import { useRouter, onBeforeRouteLeave } from "vue-router";
export default defineComponent({
  name: "Comment",
  props: {
    totalCount: Number,
    listComment: Array,
  },
  setup(props, ctx) {
    function onStar(id: string) {
      ctx.emit("star", id);
    }
    function dateFormat(datetime: string) {
      return datetime.substr(0, 16);
    }
    onBeforeRouteLeave((to, from) => {
      console.log(to);
      console.log(from);
      if (to.name=='TopicCommentDetail' && from.name == "NewsDetail") {
        return false;
      }
    });
    return {
      dateFormat,
      onStar,
      timeFormat,
    };
  },
});
