<template>
  <div class="star-container">
    <div class="star-wapper">
      <ul>
        <li v-for="item in likeUser" :key="item.id">
          <router-link :to="{ name: 'Personal', query: { uid: item.id } }">
            <img :src="item.userImg" alt="" />
            <div>
              {{ item.nickName }}
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="star-operation">
    <div class="star">
      <span :class="likeCount > 0 ? 'select' : ''" @click="onStar()">
        <img
          v-if="likeCount > 0"
          src="@/assets/img/star/icon95.png"
          alt="点赞图片"
        />
        <!-- src="http://www.bncc.org.cn/static/images/topiclaud.png" -->
        <img
          v-else
          src="@/assets/img/dz.svg"
          alt="点赞图片"
        />
        <!-- src="http://www.bncc.org.cn/Content/images/dz.svg" -->
        <i style="font-style: normal">{{ likeUserCount }}</i>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Recommend",
  emits:['star'],
  props: {
    likeCount: Number,
    likeUser: Array,
    likeUserCount: Number,
  },
  setup(props, ctx) {
    function onStar() {
      ctx.emit("star");
    }
    return {
      onStar,
    };
  },
});
</script>

<style lang="scss" scoped>
.star-container {
  position: relative;
}
.star-operation {
  position: relative;
  height: 80px;
  &::after {
    content: "";
    width: 100%;
    border-bottom: 1px solid #eee;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  border-bottom: 0.16rem solid #f4f4f4;
}
.star {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  // margin-left: -35px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1;
  span {
    margin: 0 auto;
    margin-top: 7.5px;
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 50%;
    border: 1px solid #eee;
    text-align: center;
    background-color: #fff;
    font-size: 14px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      margin-left: 3px;
    }
  }
  img {
    width: 19px;
    height: 19px;
  }
}
.select {
  color: #df0024 !important;
}
.star-wapper {
  padding: 0 15px;
  padding-top: 30px;
  padding-bottom: 20px;

  img {
    width: 50px;
    height: 50px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    margin-bottom: 5px;
  }
  ul {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    li {
      float: left;
      width: 60px;
      text-align: center;
      box-sizing: content-box;
      margin: 4px;
      text-align: center;
      color: #666;
      overflow: hidden;
      font-size: 14px;
    }
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      width: 100%;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
